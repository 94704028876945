<template>
  <div>
    <el-table
        :data="tableData"
        row-key="id"
        border
        style="width: 99%;margin: 1% auto"
        :row-style="{height: '38px'}"
        :cell-style="{padding: '0'}"
        :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
        ref="table">
      <el-table-column
          prop="viewName"
          label="页面名称"
          sortable
          align="center">
      </el-table-column>
      <el-table-column
          prop="viewType"
          label="所属模块"
          sortable
          align="center">
      </el-table-column>
      <el-table-column
          prop="gradeNames"
          label="页面等级"
          align="center">
      </el-table-column>
      <el-table-column
          label="是否已分配"
          align="center">
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.isAssign === 1 ? 'success' : 'danger'"
              disable-transitions>{{ scope.row.isAssign === 1 ? "已分配" : "未分配" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center">
        <template slot-scope="scope">
          <el-button size="mini"
                     type="primary"
                     @click="editViewFilter(scope.row)">修改
          </el-button>
          <el-button size="mini"
                     type="success"
                     :disabled="scope.row.isAssign === 1"
                     @click="assign(scope.row)">一键分配
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :title="'页面等级修改('+dialogTitle+')'"
        width="25%"
        top="25vh"
        :visible.sync="dialogState"
        :modal-append-to-body="false"
        @close="dialogState = false">
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col :span="20">
            <el-form-item label="页面等级" prop="gradeIds">
              <el-select v-model="form.gradeIds" multiple placeholder="课程类型" style="width: 100%">
                <el-option
                    v-for="item in gradeList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogState = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit()" size="small">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "mobile-view-list",
  data() {
    return {
      tableData: [],
      value: false,
      dialogState: false,
      dialogTitle: "",
      gradeList:[],
      form: {
        id: "",
        viewFilter: "",
        gradeIds: [],
      },
      originalGradeIds: "",
      rules: {
        gradeIds: [
          {required: true, message: '请选择等级', trigger: 'change'},
        ],
      },
    }
  },
  created: function () {
    this.queryMobileView();
    this.queryProductGradeIds()
  },
  methods: {
    queryProductGradeIds() {
      this.$selectUtils.queryProductGradeIds().then(response => {
        this.gradeList = response.data.data
      })
    },
    queryMobileView: function () {
      this.$axios({
        method: "get",
        url: "/mobileView/queryMobileViewList",
      }).then(response => {
        this.tableData = response.data.data;
      });
    },
    editViewFilter: function (data) {
      this.dialogState = true
      this.form.gradeIds = data.gradeIds
      this.originalGradeIds = data.gradeIds
      this.form.id = data.id
      this.dialogTitle = data.viewName
    },
    assign: function (data) {
      this.$axios({
        method: "get",
        url: "/mobileView/assignMobileView",
        data: {
          id: data.id,
          gradeIds: data.gradeIds,
        },
      }).then(response => {
        if (response.data.code === 200) {
          this.$message({
            message: response.data.msg,
            type: 'success',
            duration: 1000,
          });
          this.queryMobileView();
        } else {
          this.$message.error({
            message: response.data.msg,
            duration: 1000,
          });
        }
      });
    },
    grade(val) {
      switch (val) {
        case 1:
          return "基础版"
        case 2:
          return "经销商版"
        case 5:
          return "管理员"
      }
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "post",
            url: "/mobileView/updateMobileViewFilter",
            data: {
              ...this.form,
              originalGradeIds: this.originalGradeIds,
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message({
                message: response.data.msg,
                type: 'success',
                duration: 1000,
              });
              setTimeout(() => {
                this.queryMobileView()
                this.dialogState = false
              }, 1000)
            } else {
              this.$message.error({
                message: response.data.msg,
                duration: 1000,
              });
            }
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>